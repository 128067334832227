import React from "react"
import * as models from "../models/index"
import { DonutEmergentReaderResponse } from "../models/donuts"
import DonutChart from "./DonutChart"
import AlphabetIcon from "./DonutIcons/Alphabet"
import AwarenessIcon from "./DonutIcons/Awareness"
import { RequestError } from "models/lib/modelRequest"
import { toast } from "react-toastify"

export default class EmergentReaderDonuts extends React.Component<{
  studentID?: string
  onLoaded?: (donutsResponse: DonutEmergentReaderResponse) => void
  refresh?: boolean
}, {
  donutData: DonutEmergentReaderResponse
  loading: boolean
}> {
  state = {
    loading: true,
    donutData: null
  }
  componentDidMount() {
    this.loadDonuts()
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.refresh && this.props.refresh) {
      this.loadDonuts()
    }
  }

  loadDonuts = () => {
    models.donuts.getEmergentReaderProgress({
      studentID: this.props.studentID,
    }).then((donutData) => {
      this.setState({ donutData }, () => {
        if (typeof this.props.onLoaded === 'function') {
          this.props.onLoaded(donutData)
        }
      })
    }).catch((error: RequestError) => {
      toast.error(error)
    }).finally(() => {
      this.setState({ loading: false })
    })
  }

  render() {

    if (this.state.loading) {
      return <></>
    }

    var { alphabet, awareness } = this.state?.donutData
    return (
      <div id="donut-charts" className="row d-flex justify-content-center">
         <div className="col-4 text-center">
            <DonutChart
              label="Alphabet"
              total={alphabet.total}
              completed={alphabet.completed}
              baseColor={"#0055BB"}
              Icon={AlphabetIcon}
            />
        </div>

        <div className="col-4 text-center">
          <DonutChart
            label="Awareness"
            total={awareness.total}
            completed={awareness.completed}
            baseColor={"#0055BB"}
            Icon={AwarenessIcon}
          />
        </div>

        <h3 id="progress-message" className="w-100 pt-3 pb-3 text-center"></h3>
      </div>
    )
  }
}
