import React from "react"

const WIDTH = 65, HEIGHT = 61, X_OFFSET = 0, Y_OFFSET = 0

export default function AwarenessIcon() {
    return (
        <g fill="#FFFFFF" transform={`translate(-${(WIDTH/2) + X_OFFSET}, -${(HEIGHT/2) + Y_OFFSET})`}>
            <path d="M27.6547 17.5C28.0954 11.2791 31.4847 2 43.5145 2C55.5442 2 62.1539 12.6037 62.1539 20.9454C62.1539 29.287 57.9237 34.6313 54.2222 37.9114C52.1953 39.4195 50.653 40.3149 49.4632 42.1529C48.2735 43.9909 47.7447 48.5152 46.0262 52.3325C44.3076 56.1499 41.5315 58.6947 36.7725 58.9775C32.0135 59.2603 29.0737 56.8359 27.2545 54.0291C25.464 51.2667 26.1543 45 26.1543 45" stroke="#0055BB" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M35.0234 22C35.0234 12.9999 37.6887 10 44.1715 10C50.6543 10 54.0677 15.8284 53.6543 21.5" stroke="#0055BB" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M35.0483 22.6117C37.2365 21.9153 41.3042 24.3189 41.3051 28.8682C41.306 33.4174 38.0234 35.0001 33.9106 34.5556C32.6922 37.0031 33.0234 40.5001 33.0234 40.5001C33.0234 40.5001 33.1233 46.0001 37.5233 46.0001" stroke="#0055BB" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M9.10128 11.824C8.53365 12.7833 8.77113 14.0753 9.64376 14.7112C14.4575 18.2192 17.3346 24.1201 17.3346 30.5003C17.3346 36.8804 14.4575 42.7813 9.64383 46.2893C8.77108 46.9244 8.53363 48.2173 9.10134 49.1758C9.64885 50.1008 10.8196 50.4453 11.7342 49.7778C17.6164 45.4902 21.125 38.2813 21.125 30.4995C21.125 22.7176 17.6164 15.5095 11.7342 11.2219L11.7342 11.2219C10.853 10.5798 9.67231 10.856 9.10128 11.824ZM9.10128 11.824C9.10129 11.824 9.1013 11.8239 9.10131 11.8239L9.20889 11.8876L9.10123 11.8241C9.10124 11.824 9.10126 11.824 9.10128 11.824ZM1.91005 33.3276C1.06062 33.8484 0.751135 35.0338 1.21865 35.9771C1.6891 36.9244 2.75876 37.2608 3.60453 36.7449C5.66995 35.4815 6.95325 33.0888 6.95325 30.4986C6.95325 27.9085 5.66995 25.5166 3.6038 24.2541L1.91005 33.3276ZM1.91005 33.3276C2.85387 32.7516 3.44064 31.6678 3.44064 30.4995M1.91005 33.3276L3.44064 30.4995M3.44064 30.4995C3.44064 29.332 2.85387 28.2473 1.90932 27.6713L3.44064 30.4995ZM7.77329 17.7036L7.77325 17.7036C6.88913 17.0771 5.72112 17.3747 5.16901 18.3501C4.62116 19.318 4.87263 20.6031 5.7476 21.224L5.74762 21.224C8.62028 23.2616 10.3394 26.7271 10.3394 30.4995C10.3394 34.2718 8.62028 37.7374 5.74762 39.7749C4.87256 40.395 4.62122 41.6802 5.16898 42.6488L5.169 42.6488C5.67445 43.5421 6.82348 43.9704 7.7734 43.2953C11.7387 40.4832 14.1013 35.6989 14.1013 30.4995C14.1013 25.3008 11.7387 20.5157 7.77329 17.7036ZM1.10603 24.9661L1.10592 24.9663C0.612222 25.9624 0.934652 27.2203 1.84399 27.7778L1.84424 27.778C2.74909 28.3298 3.31564 29.3726 3.31564 30.4995C3.31564 31.6272 2.74909 32.6691 1.84493 33.2209L1.84472 33.2211C0.935383 33.7786 0.612952 35.0365 1.10665 36.0326L1.1067 36.0327C1.60662 37.0394 2.7554 37.4092 3.66962 36.8516L3.66976 36.8515C5.77531 35.5636 7.07825 33.1287 7.07825 30.4986C7.07825 27.8686 5.7753 25.4345 3.66898 24.1474L3.66866 24.1472C2.753 23.5914 1.60519 23.9637 1.10603 24.9661Z" fill="#0055BB" stroke-width="0.25"/>
        </g>
)
}
