import axios, { AxiosInstance } from 'axios'

const transport: AxiosInstance = axios.create({
  withCredentials: true,
  headers: { Pragma: 'no-cache' },
})

let configuredUrl: string = ''

function type(requestType: string | number) {
  return (url: string, data = {}, ...parm: any) => {
    const request = transport[requestType]
    return request(configuredUrl + url, data, ...parm).then(({ data }: any) => {
      return Promise.resolve(data)
    }).catch((error: { response: { data: string } }) => {
      var message = error?.response?.data ?? "There has been an error connecting to the server."

      if (!window.navigator.onLine) {
        message = "You don't appear to be online."
      }
      throw message
    }
    )
  }
}

function configure(url: string) {
  configuredUrl = url
}

export default {
  get: type('get'),
  post: type('post'),
  delete: type('delete'),
  put: type('put'),
  configure: configure,
  axios: transport,
}