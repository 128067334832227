import React from "react"

export interface Props<ModelResponse, ModelProps> {
    model: (props?: ModelProps) => Promise<ModelResponse>
    onError?: (err: any) => any
    //Props for that function
    props?: ModelProps
    //DependencyList for when to refresh it
    deps?: React.DependencyList
}

/**
 * Hook for handling common issues with async api requests.
 * 
 * Things this handles:
 * - A loading boolean for the response
 * - Canceling requests if the component has been unmounted
 * 
 * Example:
 * ```tsx
 * const { response: user, loading: userLoading } = useModel(user.get, {
 *  username: "bob"
 * })
 * ```
 */
export default function useModel<ModelResponse, ModelProps>({
    model,
    props,
    deps = [],
    onError,
}: Props<ModelResponse, ModelProps>) {
    const [response, setResponse] = React.useState<ModelResponse | null>(null)
    const [loading, setLoading] = React.useState(true)
    const [error, setError] = React.useState(false)

    let isUnmounted = React.useRef(false)

    // Need to use a ref here otherwise the variable will be reinitialized every render
    let currentRefreshId = React.useRef("")

    let refreshModel = () => {
        if (isUnmounted.current) {
            console.warn("refreshModel has been called after the component was unmounted. Please make sure you're cleaning up your state.")
            return
        }

        const id = String(Math.random())
        currentRefreshId.current = id

        // Do not call the model if it has an undefined prop
        let hasUndefined = props !== undefined
            ? Object.values(props).some((prop) => prop === undefined)
            // If props is undefined then the model should still be callable
            : false

        if (!hasUndefined) {
            setLoading(true)
            model(props)
                .then((modelResponse) => {
                    if (currentRefreshId.current === id && !isUnmounted.current) {
                        setResponse(modelResponse)
                        setLoading(false)
                        setError(false)
                    }
                })
                .catch((err) => {
                    if (!isUnmounted.current) {
                        setResponse(null)
                        setLoading(false)
                        setError(true)

                        if (onError) {
                            onError(err)
                        }
                    }
                })
        } else {
            if (!isUnmounted.current) {
                setResponse(null)
                setLoading(false)
            }
        }
    }

    React.useEffect(() => {
        refreshModel()

        return () => {
            currentRefreshId.current = ""
        }
        // Justification: eslint can't properly track this since it's an array literal now
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...deps, ...(props !== undefined ? Object.values(props) : [])])

    React.useEffect(() => {
        return () => {
            isUnmounted.current = true
        }
    }, [])

    return { response, loading, error, refreshModel }
}
