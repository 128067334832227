import { RequestError } from './lib/modelRequest'
import { modelRequest } from '../../src'

export default {
  /**
   * Logs out customer or student
   */
  post: (): Promise< 'Logged out' | RequestError> => {
    return modelRequest.post('/api/logout')
  }
}
