import { RequestError } from './lib/modelRequest'
import { modelRequest } from '../../src'

/**
 * Donuts response
 */
export interface DonutReadingResponse {
  phonics: { total: number; completed: number }
  phrases: { total: number; completed: number }
  stories: {
    total: number
    completed: number
    instructionalLevelProbe: number
    weeks: number
    years: number
    wpmPercentChange: number
  },
}

export interface DonutEmergentReaderResponse {
  alphabet: { total: number; completed: number },
  awareness: { total: number, completed: number },
}

export interface DonutMathResponse {
  addition: { total: number; completed: number }
  subtraction: { total: number; completed: number }
  multiplication: { total: number; completed: number }
  division: { total: number; completed: number }
}

interface Suggested {
  suggestedPhonicsType: string,
  suggestedStory: string
}

export default {
  /**
   * Gets donut information
   */

  getReadingProgress: (variables: {
    studentID?: string
  }): Promise<DonutReadingResponse> => {
    return modelRequest.get('/api/donuts', { params: { ...variables, program: "reading" } })
  },

  getEmergentReaderProgress: (variables: {
    studentID?: string
  }): Promise<DonutEmergentReaderResponse> => {
    return modelRequest.get('/api/donuts', { params: { ...variables, program: "emergent reader" } })
  },

  getMathProgress: (variables: {
    studentID?: string
  }): Promise<DonutMathResponse> => {
    return modelRequest.get('/api/donuts', { params: { ...variables, program: "math" } })
  },

  getPhonicsType: (variables:{
    studentID?: string
  }): Promise<Suggested | RequestError> => {
      return modelRequest.get('/api/suggested', { params: {...variables} })
  },
}
