import modelRequest, { RequestError } from './lib/modelRequest'

type ReadingType = "phrases" | "phonics" | "alphabet" | "high" | "middle" | "elementary"
type EmergentReaderType =  "alphabet" 

export interface PhonicsResponses {
  number: number
  response: string
  previousError: boolean
}

export interface AlphabetResponses {
  number: number
  response: string
  previousError: boolean
}

export interface PhrasesResponses {
  phraseNumber: number
  responseNumber: number
  response: string
  previousError: boolean
}

export interface StoriesResponses {
  paragraph: number
  centered: 'No' | 'Yes'
  lines: {
    line: number
    responses: {
      number: number
      response: string
      previousError: boolean
    }[]
  }[]
}

export type MathType = "multiplication" | "division" | "addition" | "subtraction"

export interface MathResponse {
  id: string
  responseNumber: number
  firstOperand: number
  secondOperand: number
  operator: "*" | "/" | "+" | "-"
}

interface Probe {
  /**
   * boolean that indicates it is the first time doing a probe of this TYPE (ie phonics, phrases, elem story, etc)
   */
  firstTime: boolean
  /**
   * boolean for if this specific probe has been done before
   */
  completedBefore: boolean
  /**
   * title of probe
   */
  title: string
  /**
   * numeric value that represents the last response read on the last submission of this probe, 0 if this is the first submission
   */
  lastRead: number
  /**
   * - max number of responses (simply the number of responses in the probe)
   */
  responsesMax: number
  studentCode: { code: string; expires: Date }
  responses: PhonicsResponses[] | AlphabetResponses[] | PhrasesResponses[] | StoriesResponses[] | MathResponse[]
}

export default {
  /**
   * Gets the probe responses
   */
  get: (variables: {
    studentID?: string
    type: ReadingType | MathType | EmergentReaderType
    number: number
  }): Promise<Probe & RequestError> => {
    return modelRequest.get('/api/probe', { params: { ...variables } })
  },
}
