import React from "react"
import * as models from "../models/index"
import { DonutMathResponse } from "../models/donuts"
import DonutChart from "./DonutChart"
import AdditionIcon from "./DonutIcons/Addition"
import SubtractionIcon from "./DonutIcons/Subtraction"
import MultiplicationIcon from "./DonutIcons/Multiplication"
import DivisionIcon from "./DonutIcons/Division"
import { RequestError } from "models/lib/modelRequest"
import { toast } from "react-toastify"
export default class MathDonuts extends React.Component<{
  studentID?: string
  onLoaded?: (donutsResponse: DonutMathResponse) => void
  refresh?: boolean
}, {
  donutData: DonutMathResponse
  loading: boolean
}> {
  state = {
    loading: true,
    donutData: null
  }

  componentDidMount() {
    this.loadDonuts()
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.refresh && this.props.refresh) {
      this.loadDonuts()
    }
  }

  loadDonuts = () => {
    models.donuts.getMathProgress({
      studentID: this.props.studentID
    }).then((donutData) => {
      this.setState({ donutData }, () => {
        if (typeof this.props.onLoaded === 'function') {
          this.props.onLoaded(donutData)
        }
      })
    }).catch((error: RequestError) => {
      toast.error(error)
    }).finally(() => {
      this.setState({ loading: false })
    })
  }

  render() {

    if (this.state.loading) {
        return <></>
    }

    var { addition, subtraction, multiplication, division } = this.state?.donutData
    return (
      <div className="row">
        <div className="col-3 text-center">
          <DonutChart
            label="Addition"
            total={addition.total}
            completed={addition.completed}
            baseColor={"#DF554B"}
            Icon={AdditionIcon}
          />
        </div>
        <div className="col-3 text-center">
          <DonutChart
            label="Subtraction"
            total={subtraction.total}
            completed={subtraction.completed}
            baseColor={"#DF554B"}
            Icon={SubtractionIcon}
          />
        </div>
        <div className="col-3 text-center">
          <DonutChart
            label="Multiplication"
            total={multiplication.total}
            completed={multiplication.completed}
            baseColor={"#DF554B"}
            Icon={MultiplicationIcon}
          />
        </div>
        <div className="col-3 text-center">
          <DonutChart
            label="Division"
            total={division.total}
            completed={division.completed}
            baseColor={"#DF554B"}
            Icon={DivisionIcon}
          />
        </div>
        {/* A spacing for the progress message, to ensure that the Math/Reading donuts are the same size. */}
        <h3 id="progress-message" className="w-100 pt-3 pb-3"></h3>
    </div>
    )
  }
}
