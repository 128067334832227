import React from "react"
import * as models from "../models/index"
import { DonutReadingResponse } from "../models/donuts"
import DonutChart from "./DonutChart"
import StoriesIcon from "./DonutIcons/Stories"
import { RequestError } from "models/lib/modelRequest"
import { toast } from "react-toastify"

export default class StoriesDonut extends React.Component<{
  studentID?: string
  onLoaded?: (donutsResponse: DonutReadingResponse) => void
  refresh?: boolean
}, {
  donutData: DonutReadingResponse
  loading: boolean
}> {
  state = {
    loading: true,
    donutData: null
  }
  componentDidMount() {
    this.loadDonuts()
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.refresh && this.props.refresh) {
      this.loadDonuts()
    }
  }

  loadDonuts = () => {
    models.donuts.getReadingProgress({
      studentID: this.props.studentID,
    }).then((donutData) => {
      this.setState({ donutData }, () => {
        if (typeof this.props.onLoaded === 'function') {
          this.props.onLoaded(donutData)
        }
      })
    }).catch((error: RequestError) => {
      toast.error(error)
    }).finally(() => {
      this.setState({ loading: false })
    })
  }

  render() {

    if (this.state.loading) {
      return <></>
    }

    var { stories } = this.state?.donutData
    return (
      <div id="donut-charts" className="text-center">
        <DonutChart
          label="Stories"
          total={stories.total}
          completed={stories.completed}
          baseColor={"#0055BB"}
          Icon={StoriesIcon}
        />
      </div>
    )
  }
}
