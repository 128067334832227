import React from 'react'
import Response from './Response'
import BeatLoader from 'react-spinners/BeatLoader'
//import ProbeHelp from './ProbeHelp'

function Stories({
  responses,
  exerciseNumber,
  title,
  indentedParagraphs,
  onClick = () => { },
  onDoubleClick = () => { },
  hideGhosting = false,
  Help = () => <React.Fragment />
}) {
  var renderedResponses = 0
  return (
    <React.Fragment>
      {responses.length === 0 ? (
        <div style={{ margin: '0 auto', display: 'table', height: '50vh' }}>
          <div
            style={{
              display: 'table-cell',
              verticalAlign: 'middle',
              textAlign: 'center',
            }}
          >
            <BeatLoader size={15} color={'#123abc'} loading={true} />
          </div>
        </div>
      ) : (
        <React.Fragment>
          <h1 className='h3 mb-4'>
            Stories Exercise {exerciseNumber} - {title}
            <Help />
          </h1>

          <div
            id="probe-contents"
            className={`content-block-medium story-text`}
          >
            {responses.map((paragraph, i) => {
              return (
                <div
                  key={i}
                  className={`response-container px-1 ${indentedParagraphs ? 'indented' : ''
                    } ${paragraph.centered ? 'centered' : ''}`}
                >
                  {paragraph.lines.map((line, i) => {
                    return (
                      <p key={i} className="px-3 px-lg-0">
                        {line.responses.map((response, i: number) => {
                          renderedResponses++
                          return (
                            <Response
                              key={i}
                              hadPreviousError={
                                !hideGhosting && response.previousError
                              }
                              responseNumber={renderedResponses}
                              response={response.response}
                              zeroWidthSpace={true}
                              nextResponse={
                                line.responses[i + 1]?.response
                              }
                              noHyphenedSpacing
                              onClick={onClick.bind(null, renderedResponses, {
                                responseNumber: response.number,
                                paragraphNumber: paragraph.paragraph,
                                lineNumber: line.line,
                              })}
                              onDoubleClick={onDoubleClick.bind(
                                null,
                                renderedResponses
                              )}
                            />
                          )
                        })}
                      </p>
                    )
                  })}
                </div>
              )
            })}
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

export default Stories
