import { RequestError } from './lib/modelRequest'
import { modelRequest } from '../../src'

type Login = string

export default {
  /**
   * Logs in the user as a student or customer
   */
  post: (
    username: string,
    password: string,
    type: 'student' |  'customer'
  ): Promise<Login & RequestError> => {
    return modelRequest.post('/api/login', {
      username: username,
      password: password,
      type: type,
    })
  },
}
