import { RequestError } from './lib/modelRequest'
import { modelRequest } from '../../src'

export interface ProbeDetails {
  title: string
  link: string
}

export interface SelectedReadingProbe {
  program: "reading"
  phonics?: ProbeDetails
  phrases?: ProbeDetails
  stories?: ProbeDetails
}
export interface SelectedEmergentReaderProbe {
  program: "emergent reader"
  alphabet?: ProbeDetails
}
export interface SelectedMathProbe {
  program: "math"
  addition?: ProbeDetails
  subtraction?: ProbeDetails
  multiplication?: ProbeDetails
  division?: ProbeDetails
}

export type SelectedProbe = SelectedReadingProbe | SelectedMathProbe | SelectedEmergentReaderProbe

export default {
  /**
   * Gets the probes selected by the teacher
   */
  get: (): Promise<SelectedProbe & RequestError> => {
    return modelRequest.get('/api/students/student-probes')
  },
}
