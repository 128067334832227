import React from "react"
import * as models from "../models/index"
import { DonutEmergentReaderResponse } from "../models/donuts"
import DonutChart from "./DonutChart"
import AwarenessIcon from "./DonutIcons/Awareness"
import { toast } from "react-toastify"

export default class AwarenessDonut extends React.Component<{
  studentID?: string
  onLoaded?: (donutsResponse: DonutEmergentReaderResponse) => void
  refresh?: boolean
}, {
  donutData: DonutEmergentReaderResponse
  loading: boolean
}> {
  state: {
    loading: boolean
    donutData: DonutEmergentReaderResponse
  } = {
    loading: true,
    donutData: null
  }
  componentDidMount() {
    this.loadDonuts()
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.refresh && this.props.refresh) {
      this.loadDonuts()
    }
  }

  loadDonuts = async () => {
    try {
      const donutData = await models.donuts.getEmergentReaderProgress({
        studentID: this.props.studentID,
      })

      this.setState({ donutData }, () => {
        if (typeof this.props.onLoaded === "function") {
          this.props.onLoaded(donutData)
        }
      })
    } catch(error) {
      toast.error(error)
    } finally {
      this.setState({ loading: false })
    }
  }

  render() {

    if (this.state.loading) {
      return <></>
    }

    var { awareness } = this.state?.donutData
    return (
      <div id="donut-charts" className="text-center">
        <DonutChart
            label="Awareness"
            total={awareness.total}
            completed={awareness.completed}
            baseColor={"#0055BB"}
            Icon={AwarenessIcon}
          />
      </div>
    )
  }
}
