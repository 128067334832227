import React from "react"
import * as models from "../models/index"
import { DonutReadingResponse } from "../models/donuts"
import DonutChart from "./DonutChart"
import PhonicsIcon from "./DonutIcons/Phonics"
import PhrasesIcon from "./DonutIcons/Phrases"
import StoriesIcon from "./DonutIcons/Stories"
import { RequestError } from "models/lib/modelRequest"
import { toast } from "react-toastify"

export default class ReadingDonuts extends React.Component<{
  studentID?: string
  onLoaded?: (donutsResponse: DonutReadingResponse) => void
  refresh?: boolean
}, {
  donutData: DonutReadingResponse
  loading: boolean
}> {
  state = {
    loading: true,
    donutData: null
  }
  componentDidMount() {
    this.loadDonuts()
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.refresh && this.props.refresh) {
      this.loadDonuts()
    }
  }

  loadDonuts = () => {
    models.donuts.getReadingProgress({ 
      studentID: this.props.studentID 
    }).then((donutData) => {
      this.setState({ donutData }, () => {
        if (typeof this.props.onLoaded === 'function') {
          this.props.onLoaded(donutData)
        }
      })
    }).catch((error: RequestError) => {
      toast.error(error)
    }).finally(() => {
      this.setState({ loading: false })
    })
  }

  render() {

    if (this.state.loading) {
      return <></>
    }

    var { stories, phrases, phonics } = this.state?.donutData
    var { years, weeks, wpmPercentChange } = stories
    return (
      <div id="donut-charts" className="row">
        <div className="col-4 text-center">
          <DonutChart
            label="Phonics"
            total={phonics.total}
            completed={phonics.completed}
            baseColor={"#0055BB"}
            Icon={PhonicsIcon}
          />
        </div>

        <div className="col-4 text-center">
          <DonutChart
              label="Phrases"
              total={phrases.total}
              completed={phrases.completed}
              baseColor={"#0055BB"}
              Icon={PhrasesIcon}
            />
        </div>

        <div id="stories" className="col-4 text-center">
          <DonutChart
              label="Stories"
              total={stories.total}
              completed={stories.completed}
              baseColor={"#0055BB"}
              Icon={StoriesIcon}
            />
        </div>

        <h3 id="progress-message" className="w-100 pt-3 pb-3 text-center">
          {years > 0
            ? `In
                        ${
                          weeks < 8.69049 //2 months
                            ? weeks + " weeks"
                            : weeks < 52.1429 //1 year
                            ? (weeks / 4.34524).toFixed(1) + " months"
                            : (weeks / 52).toFixed(1) + " years"
                        }
                    
                     - you have progressed by ${years} year${
                years > 1 ? "s" : ""
              }${
                wpmPercentChange > 0
                  ? ` with a ${Math.round(wpmPercentChange)}% increase in rate`
                  : ""
              }!!`
            : ""}
        </h3>
      </div>
    )
  }
}
